import Vue from "vue"

export const notificationsEvents = new Vue()

export const NOTIFICATION_TYPES = {
  SUCCESS: "SUCCESS",
  ERROR: "ERROR",
  WARNING: "WARNING",
  INFO: "INFO",
  DEFAULT: "DEFAULT",
}

export class NotificationOptions {
  constructor(options) {
    this.message = options["message"] || "Nenhuma mensagem configurada"
    this.type = options["type"] || NOTIFICATION_TYPES.DEFAULT
    this.duration = options["duration"] || 4000

    switch (this.type) {
      case NOTIFICATION_TYPES.SUCCESS:
        this.color = "success"
        break
      case NOTIFICATION_TYPES.ERROR:
        this.color = "error"
        break
      case NOTIFICATION_TYPES.WARNING:
        this.color = "warning"
        break
      case NOTIFICATION_TYPES.INFO:
        this.color = "info"
        break
      default:
        this.color = ""
        break
    }
  }
}

export default (params) => {
  if (!(params instanceof NotificationOptions))
    return

  notificationsEvents.$emit("add-notification", params)
}