import Vue from "vue"
import VueRouter from "vue-router"

Vue.use(VueRouter)

const routes = [
  {
    path: "/",
    name: "entry-point",
    component: () => import("../views/EntryPoint.vue"),
  },
  {
    path: "/login/",
    name: "login",
    component: () => import("../views/Login.vue"),
  },
  {
    path: "/register/",
    name: "register",
    component: () => import("../views/Register.vue"),
  },
  {
    path: "/authorize/",
    name: "authorize",
    component: () => import("../views/AuthorizeScopes.vue"),
  },
  {
    path: "/recover-password/",
    name: "recover-password",
    component: () => import("../views/RecoverPassword.vue"),
  },
  {
    path: "/terms-of-use",
    name: "terms-of-use",
    component: () => import("../views/TermsOfUse.vue"),
  },
  {
    path: "/privacy-policy",
    name: "privacy-policy",
    component: () => import("../views/PrivacyPolicy.vue"),
  },
  {
    path: "/verify-email",
    name: "verify-email",
    component: () => import("../views/VerifyEmail.vue"),
  },
  {
    path: "/post-register",
    name: "post-register",
    component: () => import("../views/PostRegister.vue"),
  },
  {
    path: "/invalid-register-key",
    name: "invalid-register-key",
    component: () => import("../views/InvalidRegisterKey.vue"),
  },
  {
    path: "/error",
    name: "error",
    component: () => import("../views/Error.vue" +
    ""),
  },
]

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
})

export default router
