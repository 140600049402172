var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.current
        ? _c(
            "v-snackbar",
            {
              attrs: {
                color: _vm.current.color,
                timeout: 0,
                bottom: "",
                left: ""
              },
              model: {
                value: _vm.active,
                callback: function($$v) {
                  _vm.active = $$v
                },
                expression: "active"
              }
            },
            [
              _vm._v(" " + _vm._s(_vm.current.message) + " "),
              _c(
                "v-btn",
                { attrs: { dark: "", icon: "" }, on: { click: _vm.onClose } },
                [_c("v-icon", [_vm._v(" " + _vm._s(_vm.closeIcon) + " ")])],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }