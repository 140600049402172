import Vue from "vue"
import App from "./App.vue"
import router from "./router"
import store from "./store"
import vuetify from "./plugins/vuetify"
import notify from "./helpers/notify"
import {Plugin} from "vue-fragment"
import {HorusAuthSSO} from "./helpers/auth-horus"
import {AUTH_URL} from "./globals"
import VueTheMask from "vue-the-mask"
import VueGtm from "vue-gtm"

Vue.use(Plugin)
Vue.use(VueTheMask)
Vue.use(VueGtm, {
  id: ["GTM-WRWJSSB", "GTM-N9CLZPC"], // Your GTM single container ID or array of container ids ['GTM-xxxxxxx', 'GTM-yyyyyyy']
  enabled: true, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
  debug: true, // Whether or not display console logs debugs (optional)
  loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
  vueRouter: router, // Pass the router instance to automatically sync with router (optional)
})

Vue.prototype.$notify = notify


const apiAuth = new HorusAuthSSO()
// TODO: Get the auth link and the TLS verification from .env file
apiAuth.setUp(
  AUTH_URL,
  "/login/",
  "/oauth/authorize/",
  "/oauth/check-authorized-scope/",
  "/users/register-user/",
  "/validate-session-code/",
  "/users/verify-email/",
  false,
)
Vue.prototype.$apiAuth = apiAuth

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
}).$mount("#app")
