import {getCookie, removeCookie, setCookie} from "./utils";


export class AuthError extends Error {
  constructor(message, data) {
    super(message)

    this.data = data
  }
}


export class TLSError extends AuthError {
}


export class WellKnownVerificationError extends AuthError {
}


export class LoginError extends AuthError {
}

export class MaxSessionsReached extends AuthError {
}


export class TooManyFailedLoginAttempts extends AuthError {
}


export class NotLoggedInError extends AuthError {
}


export class SessionExpiredError extends AuthError {
}


export class AuthorizeScopesError extends AuthError {
}


export class RegisterBadRequest extends AuthError {
}


export class RequestResetPasswordBadRequest extends AuthError {
}


export class ResetPasswordBadRequest extends AuthError {}


export class ResetPasswordInvalidToken extends ResetPasswordBadRequest {}


export class ResetPasswordExpiredToken extends ResetPasswordBadRequest {}


export class VerifyEmailInvalidToken extends AuthError {}


function getUserSession() {
  // Verify if there is a logged user in the session
  const sessionCode = getCookie(SESSION_CODE_KEY)
  if (!sessionCode) {
    throw new NotLoggedInError("There is no user logged in")
  }

  return { sessionCode }
}


function createParams(url, params) {
  // https://github.com/github/fetch/issues/256#issuecomment-272717297
  url = new URL(url)
  Object.keys(params).forEach(
    key => url.searchParams.append(key, params[key])
  )

  return url
}


export function checkOAuthParams(objParams) {
  // Base required params for the login
    const requiredParams = [
      "client_id",
      "redirect_uri",
      "response_type",

      "scope",
      "state",
      "nonce",
    ]

    // Verify if all the params are valid
    const queryParamsKeys = Object.keys(objParams)
    const intersection = requiredParams.filter(val => queryParamsKeys.includes(val))
    if (intersection.length !== requiredParams.length) {
      return false
    }

    // Add the non required params
    const totalParams = [
      ...requiredParams,
      "id_token_hint", "login_hint", "prompt", "display", "claims", "rft", "register_key"
    ]

    const notIncludes = queryParamsKeys.filter(val => !totalParams.includes(val))
    return notIncludes.length === 0;
}


async function handleLoginError(res) {
  // Create an throwable Error from an bad login response
  const jsonData = await res.json()
  const error = jsonData.error
  if (!error) {
    jsonData.status_code = res.status
    return new AuthError("Request error when trying to login", jsonData)
  }

  switch (error) {
      case "login-failed":
        return new LoginError("Couldn't login with the provided credentials")
      case "max-sessions-reached":
        return new MaxSessionsReached("Max sessions reached")
      case "too-many-requests":
        return new TooManyFailedLoginAttempts("Too many failed login attempts")
      default:
        jsonData.status_code = res.status
        return new AuthError("Request error when trying to login", jsonData)
    }
}


export const SESSION_CODE_KEY = "sc"


export class HorusAuthSSO {
  constructor() {
    this.authUrl = null
    this.loginPath = null
    this.authorizePath = null
    this.checkScopesPath = null
    this.registerPath = null
  }

  setUp(authUrl, loginPath, authorizePath, checkScopesPath, registerPath, validateSessionCodePath, verifyPasswordPath, tls = true) {
    this.authUrl = new URL(authUrl)
    this.loginPath = loginPath
    this.authorizePath = authorizePath
    this.checkScopesPath = checkScopesPath
    this.registerPath = registerPath
    this.validateSessionCodePath = validateSessionCodePath
    this.verifyPasswordPath = verifyPasswordPath

    if (tls) {
      if (this.authUrl.protocol !== "https") {
        throw new TLSError(
          "The param 'authUrl' must use 'https' protocol", this.authUrl
        )
      }
    }
  }

  async loginUser(user, password, logoutLastSession) {
    const basicAuthString = btoa(`${user}:${password}`)
    const headers = {
      "Authorization": `Basic ${basicAuthString}`,
      "Content-Type": "application/json",
    }

    const loginResponse = await fetch(
      this.createUrl(this.loginPath),
      {
        method: "POST",
        headers: headers,
        body: JSON.stringify({logout_last_session: logoutLastSession})
      }
    )

    if (!loginResponse.ok) {
      throw await handleLoginError(loginResponse)
    }

    const loginData = await loginResponse.json()

    const sessionCode = loginData.data["session_code"]
    const expiresAt = loginData.data["expires_at"]

    let curDate = new Date()
    let dateObj = new Date(
      curDate.getFullYear() + 1,
      curDate.getMonth(),
      curDate.getDate(),
    )

    setCookie(SESSION_CODE_KEY, sessionCode, dateObj, true)

    return {
      sessionCode: sessionCode,
      expiresAt: expiresAt,
    }
  }

  async validateSessionCode() {
    const sessionCode = getCookie(SESSION_CODE_KEY)
    if (!sessionCode) {
      return false
    }

    const codeValidResponse = await fetch(
      this.createUrl(this.validateSessionCodePath),
      {
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
      }
    )

    if (codeValidResponse.ok) {
      return true
    }

    removeCookie(SESSION_CODE_KEY)
    return false
  }

  
  async freeToRegister(register_key) {
    const finalUrl = this.createUrl("/users/check-register-bl-partner-flag/")
    const registerResponse = await fetch(
      finalUrl,
      {
        method: "POST",
        body: JSON.stringify({
          str_register_key: register_key,
        }),
        headers: {
          "Content-Type": "application/json",
        }
      },
    )

    const jsonData = await registerResponse.json()

    if (!registerResponse.ok) {
      if (registerResponse.status === 400) {
        throw new RegisterBadRequest("Bad request on register", jsonData)
      }
      throw new AuthError("Unknown Error", jsonData)
    }
    
    return jsonData
  }


  async createRegisterCheckoutSession(
    strUsername, strPassword, strEmail,
    strName, strPhoneNumber, abPhoneRegion,
    abCountry, referralToken, clientId, 
    redirectUri, register_key
  ) {
    const finalUrl = this.createUrl("/users/create-register-checkout-session/")

    const registerCheckoutSessionResponse = await fetch(
      finalUrl,
      {
        method: "POST",
        body: JSON.stringify({
          client_id: clientId,
          redirect_uri: redirectUri,
          
          str_username: strUsername,
          str_password: strPassword,
          str_email: strEmail,

          str_name: strName,
          ab_phone_region: abPhoneRegion,
          str_phone_number: strPhoneNumber,
          ab_country: abCountry,

          cd_referral_token: referralToken,
          register_key: register_key,
        }),
        headers: {
          "Content-Type": "application/json",
        }
      },
    )

    const jsonData = await registerCheckoutSessionResponse.json()

    if (!registerCheckoutSessionResponse.ok) {
      if (registerCheckoutSessionResponse.status === 400) {
        throw new RegisterBadRequest("Bad request on register", jsonData)
      }

      throw new AuthError("Unknown Error", jsonData)
    }

    return jsonData
  }


  async registerUserStripe(sessionId) {
    const finalUrl = this.createUrl("/users/register-user-stripe/")
    const registerResponse = await fetch(
      finalUrl,
      {
        method: "POST",
        body: JSON.stringify({
          str_session_id: sessionId,
        }),
        headers: {
          "Content-Type": "application/json",
        }
      },
    )

    const jsonData = await registerResponse.json()

    if (!registerResponse.ok) {
      if (registerResponse.status === 400) {
        throw new RegisterBadRequest("Bad request on register", jsonData)
      }
      throw new AuthError("Unknown Error", jsonData)
    }
    
    return jsonData
  }


  async registerUser(
    strUsername, strPassword, strEmail,
    strName, strPhoneNumber, abPhoneRegion,
    abCountry, referralToken,
    clientId, redirectUri, register_key
  ) {
    const finalUrl = createParams(
      this.createUrl(this.registerPath), {
        client_id: clientId,
        redirect_uri: redirectUri
      }
    )

    const registerResponse = await fetch(
      finalUrl,
      {
        method: "POST",
        body: JSON.stringify({
          str_username: strUsername,
          str_password: strPassword,
          str_email: strEmail,

          str_name: strName,
          ab_phone_region: abPhoneRegion,
          str_phone_number: strPhoneNumber,

          ab_country: abCountry,

          cd_referral_token: referralToken,
          register_key: register_key,
        }),
        headers: {
          "Content-Type": "application/json",
        }
      },
    )

    const jsonData = await registerResponse.json()

    if (!registerResponse.ok) {
      if (registerResponse.status === 400) {
        throw new RegisterBadRequest("Bad request on register", jsonData)
      }

      throw new AuthError("Unknown Error", jsonData)
    }

    return jsonData
  }

  async authorizeScopes({
      clientId = null, redirectUri = null, responseType = null, scopes = null,
      state = null, nonce = null, prompt = null, display = null, claims = null,
      idTokenHint = null, loginHint = null
    } = {}) {

    const { sessionCode } = getUserSession()

    const finalUrl = createParams(
      this.createUrl(this.authorizePath),
      {
        client_id: clientId,
        redirect_uri: redirectUri,
        response_type: responseType,
        scope: scopes,
        state: state,
        nonce: nonce,
        prompt: prompt,
        display: display,
        claims: claims,
        id_token_hint: idTokenHint,
        login_hint: loginHint,
      }
    )

    const authorizeResponse = await fetch(
      finalUrl,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "X-Session-Code": sessionCode,
        },
      }
    )

    if (!authorizeResponse.ok) {
      const respData = await authorizeResponse.json()
      throw new AuthorizeScopesError("Error on POST authorize", respData)
    }

    if (authorizeResponse.status < 300 && authorizeResponse.status > 399) {
      throw new AuthorizeScopesError(
        "Error on POST authorize, redirect not supplied"
      )
    }

    return authorizeResponse.url
  }

  async checkAuthorizedScopes(clientId) {
    const { sessionCode } = getUserSession()

    const finalUrl = createParams(
      this.createUrl(this.checkScopesPath),
      {
        client_id: clientId
      }
    )
    const checkScopesResponse = await fetch(
      finalUrl,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "X-Session-Code": sessionCode,
        }
      }
    )

    let responseData = await checkScopesResponse.json()
    if (!checkScopesResponse.ok) {
      const error = responseData || ""

      if (error === "login-required") {
        throw new LoginError("Login required", responseData)
      }

      throw new AuthError("Check scopes error")
    }

    responseData = responseData.data || {}
    return responseData.scope || []
  }

  async requestResetPassword(userEmail) {
    const finalUrl = this.createUrl("/users/request-reset-password/")
    const requestResetPassword = await fetch(
      finalUrl,
      {
        method: "POST",
        body: JSON.stringify({
          str_email: userEmail,
        }),
        headers: {
          "Content-Type": "application/json",
        }
      }
    )

    const jsonData = await requestResetPassword.json()
    if (!requestResetPassword.ok) {
      throw new RequestResetPasswordBadRequest(jsonData, "Bad request")
    }

    return jsonData
  }

  async resetPassword(recoverToken, newPassword) {
    const finalUrl = this.createUrl("/users/reset-password/")
    const requestResetPassword = await fetch(
      finalUrl,
      {
        method: "POST",
        body: JSON.stringify({
          str_new_password: newPassword,
          str_token: recoverToken,
        }),
        headers: {
          "Content-Type": "application/json",
        }
      }
    )

    const jsonData = await requestResetPassword.json()
    if (!requestResetPassword.ok) {
      const errorIdentifier = jsonData["error"]
      if (errorIdentifier === "resource-not-found" || errorIdentifier === "invalid-token") {
        throw new ResetPasswordInvalidToken(jsonData, "Token is invalid")
      } else if (errorIdentifier === "expired-token") {
        throw new ResetPasswordExpiredToken(jsonData, "Token is expired")
      } else {
        throw new ResetPasswordBadRequest(jsonData, "Unknown error")
      }
    }

    return jsonData
  }

  async verifyEmail(verifyToken) {
    const finalUrl = this.createUrl(this.verifyPasswordPath)
    const requestVerifyEmail = await fetch(
      finalUrl,
      {
        method: "POST",
        body: JSON.stringify({
          str_token: verifyToken,
        }),
        headers: {
          "Content-Type": "application/json",
        }
      }
    )

    const jsonData = await requestVerifyEmail.json()
    if (!requestVerifyEmail.ok) {
      throw new VerifyEmailInvalidToken(jsonData, "Token is invalid")
    }

    return jsonData
  }

  createUrl(path) {
    const authUrlBase = this.authUrl.href

    // Checks if the path starts with an '/'
    //  if not, append one to the start
    if (!path.startsWith("/")) {
      path = "/" + path
    }

    return authUrlBase + path
  }
}


