export function arraysEqual(a, b) {
  if (a === b) return true
  if (a == null || b == null) return false
  if (a.length != b.length) return false

  a.sort()
  b.sort()

  for (var i = 0; i < a.length; ++i) {
    if (a[i] !== b[i]) return false
  }
  return true
}


export function strCapitalize(str) {
  return str.charAt(0).toUpperCase() + str.slice(1)
}


export function getCookie(cookieName) {
  // Reference: https://www.w3schools.com/js/js_cookies.asp

  const name = cookieName + "="

  const decodedCookie = decodeURIComponent(document.cookie)
  const cookies = decodedCookie.split(";")

  for (let cookie of cookies) {
    while (cookie.charAt(0) === " ") {
      cookie = cookie.substring(1)
    }

    if (cookie.indexOf(name) === 0) {
      return cookie.substring(name.length, cookie.length)
    }
  }

  return ""
}


export function setCookie(cookieName, cookieValue, dateObject, withDomain = true) {
  // Reference: https://www.w3schools.com/js/js_cookies.asp

  const expires = "expires=" + dateObject.toUTCString()
  let cookie = `${cookieName}=${cookieValue};${expires};path=/;SameSite=unset`
  if (withDomain) {
    const currentDomain = `.${window.location.hostname.split(".").slice(-2).join(".")}`
    cookie += `;domain=${currentDomain}`
  }

  document.cookie = cookie
}


export function removeCookie(cookieName) {
  // Reference: https://www.w3schools.com/js/js_cookies.asp
  document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`
}


export function objectToFormData(obj) {
  const formDataInput = []
  for (let [key, value] of Object.entries(obj)) {
    if (!value) {
      value = ""
    }
    formDataInput.push({
      name: key,
      value: value,
    })
  }

  return formDataInput
}
