<template>
  <v-app>
    <v-content>
      <router-view/>
    </v-content>
    <notifications/>
  </v-app>
</template>

<script>
  import Notifications from "./components/Helpers/Notifications"
  export default {
    name: "App",
    components: {Notifications},
  }
</script>

<style lang="sass">
  @import "sass/mixins"

  html
    overflow-y: auto
</style>

