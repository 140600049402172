// -------------- State -------------- //
const defaultState = () => ({
  client_id: null,
  redirect_uri: null,
  response_type: null,
  scope: null,
  state: null,
  nonce: null,
  prompt: null,
  display: null,
  claims: null,
  id_token_hint: null,
  login_hint: null,
});

const state = defaultState()

// -------------- Mutations -------------- //
const MUTATIONS_TYPES = {
  SET_OAUTH_STATE: "SET_OAUTH_STATE"
}

const mutations = {
  [MUTATIONS_TYPES.SET_OAUTH_STATE](
    state,
    {
      client_id, redirect_uri, response_type,
      scope, oauth_state, nonce, prompt, display, claims,
      id_token_hint, login_hint
    }
  ) {
    state.client_id = client_id
    state.redirect_uri = redirect_uri
    state.response_type = response_type

    state.scope = scope
    state.state = oauth_state
    state.nonce = nonce
    state.prompt = prompt
    state.display = display

    state.claims = claims

    state.id_token_hint = id_token_hint
    state.login_hint = login_hint
  }
}

// -------------- Getters -------------- //
export const GET_CLIENT_ID = "GET_CLIENT_ID"
export const GET_REDIRECT_URI = "GET_REDIRECT_URI"
export const GET_RESPONSE_TYPE = "GET_RESPONSE_TYPE"
export const GET_SCOPE = "GET_SCOPE"
export const GET_STATE = "GET_STATE"
export const GET_NONCE = "GET_NONCE"
export const GET_PROMPT = "GET_PROMPT"
export const GET_DISPLAY = "GET_DISPLAY"
export const GET_CLAIMS = "GET_CLAIMS"
export const GET_ID_TOKEN_HINT = "GET_ID_TOKEN_HINT"
export const GET_LOGIN_HINT = "GET_LOGIN_HINT"
export const GET_FULL_STATE = "GET_FULL_STATE"
export const GET_AUTHORIZE_SCOPE_PARAMS = "GET_AUTHORIZE_SCOPE_PARAMS"
export const GET_IS_OAUTH_STATE_VALID = "GET_IS_OAUTH_STATE_VALID"

const getters = {
  [GET_CLIENT_ID](state) { return state.client_id },
  [GET_REDIRECT_URI](state) { return state.redirect_uri },
  [GET_RESPONSE_TYPE](state) { return state.response_type },
  [GET_SCOPE](state) { return state.scope },
  [GET_STATE](state) { return state.state },
  [GET_NONCE](state) { return state.nonce },
  [GET_PROMPT](state) { return state.prompt },
  [GET_DISPLAY](state) { return state.display },
  [GET_CLAIMS](state) { return state.claims },
  [GET_ID_TOKEN_HINT](state) { return state.id_token_hint },
  [GET_LOGIN_HINT](state) { return state.login_hint },
  [GET_FULL_STATE](state) { return state },
  [GET_AUTHORIZE_SCOPE_PARAMS](state) {
    return {
      clientId: state.client_id,
      redirectUri: state.redirect_uri,
      responseType: state.response_type,

      scope: state.scope,
      state: state.state,
      nonce: state.nonce,
      prompt: state.prompt,
      display: state.display,
      claims: state.claims,

      idTokenHint: state.id_token_hint,
      loginHint: state.login_hint,
    }
  },
  [GET_IS_OAUTH_STATE_VALID](state) {
    const requiredStateFields = [
      "client_id",
      "redirect_uri",
      "response_type",

      "scope",
      "state",
      "nonce",
    ]
    for (const field of requiredStateFields) {
      if (!state[field] || state[field].length === 0) {
        return false
      }
    }

    return true
  }
}

// -------------- Actions -------------- //
export const SET_OAUTH_STATE = "SET_OAUTH_STATE"

const actions = {
  [SET_OAUTH_STATE](
    {commit},
    {
      client_id, redirect_uri, response_type,
      scope, state, nonce, prompt, display, claims,
      id_token_hint, login_hint
    }
  ) {

    // noinspection UnnecessaryLocalVariableJS
    const oauth_state = state

    commit(
      MUTATIONS_TYPES.SET_OAUTH_STATE,
      {
        client_id, redirect_uri, response_type,
        scope, oauth_state, nonce, prompt, display, claims,
        id_token_hint, login_hint
      }
    )
  }
}

export default {
  mutations,
  getters,
  actions,
  state,
};
