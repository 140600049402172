<template>
  <div>
    <v-snackbar v-if="current"
                v-model="active"
                :color="current.color"
                :timeout="0"
                bottom
                left
    >
      {{ current.message }}
      <v-btn
        @click="onClose"
        dark
        icon
      >
        <v-icon>
          {{closeIcon}}
        </v-icon>
      </v-btn>
    </v-snackbar>
  </div>
</template>

<script>
  import {isEmpty, isNull} from "lodash/lang"
  import {notificationsEvents} from "../../helpers/notify"
  import {mdiClose} from "@mdi/js"

  export default {
    name: "Notifications",
    data() {
      return {
        notifications: [],
        current: null,
        active: false,

        closeIcon: mdiClose,

        timeout: null
      }
    },
    methods: {
      addNotification(event) {
        this.notifications.push(event)

        if (isNull(this.current)) {
          this.current = this.notifications.pop()
          this.active = true

          if (this.current.duration !== 0) {
            clearTimeout(this.timeout)
            this.timeout = setTimeout(function () {
              this.onClose()
            }.bind(this), this.current.duration)
          }
        }
      },
      onClose() {
        this.active = false
        this.current = null

        if (isEmpty(this.notifications))
          return

        setTimeout(() => {
          this.current = this.notifications.shift()
          this.active = true

          if (this.current.duration !== 0) {
            clearTimeout(this.timeout)
            this.timeout = setTimeout(function () {
              this.onClose()
            }.bind(this), this.current.duration)
          }
        }, 500)
      },
    },
    mounted() {
      notificationsEvents.$on("add-notification", this.addNotification)
    },
  }
</script>

<style scoped>

</style>