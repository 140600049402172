import Vue from "vue"
import Vuetify from "vuetify/lib"

const LRU = require("lru-cache")

const themeCache = new LRU({
  max: 10,
  maxAge: 1000 * 60 * 60, // 1 hour
})

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#005378",
        secondary: "#424242",
        accent: "#009ec1",
        error: "#FF5252",
        info: "#2196F3",
        success: "#4CAF50",
        warning: "#FFC107",
      },
    },
    options: {
      minifyTheme: function (css) {
        return process.env.NODE_ENV === "production"
          ? css.replace(/[\r\n|\r|\n]/g, "")
          : css
      },
      themeCache,
    },
  },
  icons: {
    iconfont: "mdiSvg",
  },
})
